<template>
  <!-- ricardomanzano89@gmail.com -->
  <div class="change-password-form is-centered">
    <template v-if="getAlert == ''">
      <div class="columns" style="padding-top: 4rem;" v-if="getMsg == ''">
        <div class="column is-12">
          <div class="change-password">
            <div class="title-change-password">Cambia la contraseña</div>
            <div class="subtitle-change-password">
              <span class="label-change-password">Email: </span>
              <span>{{ getEmail }}</span>
            </div>
            <div class="margin-form">
              <ValidationProvider rules="required" vid="password" name="Password" v-slot="{ errors }">
                <Input
                  type="password"
                  label="Contraseña nueva*"
                  placeholder="Contraseña"
                  v-model="form.password"
                  :hasError="!!errors[0]"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
            <div class="margin-form-input">
              <ValidationProvider rules="required|confirmed:password" name="Password Confirmation" v-slot="{ errors }">
                <Input
                  type="password"
                  label="Confirmar Contraseña*"
                  placeholder="Confirmar Contraseña"
                  v-model="form.confirm_password"
                  :hasError="!!errors[0]"
                  :error="errors[0]"
                />
              </ValidationProvider>
              <span class="input-label-form">Ambas contraseñas deben coincidir.</span>
            </div>
            <div class="m-20">
              <JumboButton
                @click.native="onClickChangePassword"
                color="#000000"
                class="success-button-register"
                value="Guardar contraseña"
                :disabled="disabledSubmit"
                :class="{ 'disabled-message': disabledSubmit }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns" style="padding-top: 4rem;" v-if="getMsg != ''">
        <div class="column is-12">
          <div class="change-password">
            <div class="title-change-password">{{ getMsg }}</div>
            <div class="subtitle-change-password-sussess">
              Ahora puedes seguir navegando.
            </div>
            <img class="check-success-change-password" src="/icons/maps-and-flags.png" />
            <div class="m-20">
              <JumboButton
                @click.native="onClickNavPassword"
                color="#428EE6"
                class="nav-button-password"
                value="Seguir Navegando"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="columns" style="padding-top: 4rem;">
        <div class="column is-12">
          <div class="change-password">
            <div class="title-change-password">{{ getAlert }}</div>
            <div class="subtitle-change-password-sussess-error">
              Por favor inicia un nuevo proceso de recuperación.
            </div>
            <img class="check-success-change-password" src="/icons/close.png" />
            <div class="m-20">
              <JumboButton
                @click.native="onClickNavPassword"
                color="#428EE6"
                class="nav-button-password"
                value="Seguir Navegando"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import Input from '@/components/Form/Input';
import JumboButton from '@/components/Form/JumboButton';
import { ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import { clickConfirmarCambioDeContrasena } from '@/utils/analytics';
export default {
  name: 'change',
  props: {},
  components: { Input, JumboButton, ValidationProvider },
  data() {
    return {
      flagSuccess: false,
      flagError: false,
      token: '',
      form: {
        token: '',
        password: '',
        confirm_password: '',
      },
    };
  },
  computed: {
    ...mapGetters('recoverGeneral', ['getEmail', 'getMsg', 'getAlert']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    disabledSubmit() {
      return !(this.form.password == this.form.confirm_password && this.form.password != '');
    },
  },
  async created() {
    this.form.token = this.$route.query.token;
    await this.validateToken({ token: this.form.token });
  },
  beforeMount() {},
  methods: {
    ...mapActions('recoverGeneral', ['changePassword', 'validateToken']),
    onClickNavPassword() {
      this.$router.push('/tips');
    },
    async onClickChangePassword() {
      try {
        clickConfirmarCambioDeContrasena(this.getPremiumAccount, this.getIsGolstats);
        await this.changePassword(this.form);
      } catch (error) {
        // ToDo: Add message if problem with login
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.change-password-form {
  margin: 20px 10px 0;
  display: flex;
  justify-content: center;
}
.label-change-password {
  font-family: 'Circular-Std';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.89px;
  text-align: center;
  color: #494a4b;
}
.input-label-form {
  width: 100%;
  display: flex;
  height: 16px;
  margin: -7px 0 2px 16px;
  font-size: 13px;
  letter-spacing: -0.07px;
  color: #888888;
}
.nav-button-password {
  width: 290px;
  border-radius: 7px;
}
.title-change-password {
  margin: 0 37px 25px 36px;
  font-family: 'Circular-Std';
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.89px;
  text-align: center;
  color: #494a4b;
}
.margin-form-input {
  margin-top: 8px;
}
.margin-form {
  margin-top: 30px;
}
.check-success-change-password {
  margin-top: 30px;
}
.subtitle-change-password {
  margin: 4px 0 0;
  font-family: 'Avenir-Roman';
  font-size: 14px;
  margin-top: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  text-align: center;
  color: #494a4b;
}
.subtitle-change-password-sussess,
.subtitle-change-password-sussess-error {
  margin: 4px 0 0;
  font-family: 'Avenir-Roman';
  font-size: 14px;
  margin-top: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  text-align: center;
  color: #494a4b;
}
@media only screen and (max-width: 380px) {
  .subtitle-change-password-sussess-error {
    font-size: 12px;
  }
}
</style>
